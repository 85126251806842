import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BaseTemplate, PageHead } from '@myob/myob-widgets';
import { AccountDetailsWrapper } from './styles';
import { AccountForm } from './components/AccountForm';
import {
  AccountDetailsState,
  fetchAccountDetailsDataByAccountId,
  resetIsUpdateError,
  updateAccountDetailsDataByAccountUUID,
} from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from 'stores';
import LoadingWrapper from 'components/LoadingWrapper';
import { EPage } from 'telemetry/type';
import { Forbidden } from 'components/Forbidden';
import { HttpStatus } from 'helpers/request';
import ErrorComponent from 'components/ErrorComponent';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import ModalBox from '../../components/ModalComponent';
import { Notification } from '../../components/Notification';
import { ENotificationType } from '../../stores/reducers/notification/type';
import { isEmpty, omitBy } from 'lodash';
import { BusinessOrBillingAddress } from './type';
import { GtmManager } from '@my-account/tools';

const AccountDetails: React.FC = () => {
  const formRef = useRef(null);
  const dispatch: AppThunkDispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const {
    fetch: {
      data: accountInfo,
      isLoading,
      isError,
      error: { status },
    },
  } = useSelector<RootState, AccountDetailsState>((state) => state.accountDetails);

  const isUpdateError = useSelector<RootState, boolean>((state) => state.accountDetails.update.isError);

  const { selected: selectedAccount, error: accountSelectError } =
    useContext<AccountSelectContextValue>(AccountSelectContext);

  const updateModalStatus = (status: boolean) => {
    setShowModal(status);
  };

  useEffect(() => {
    if (!accountSelectError && selectedAccount?.clientId) {
      dispatch(fetchAccountDetailsDataByAccountId(selectedAccount.clientId));
    }
  }, [selectedAccount, accountSelectError]);

  useEffect(() => {
    if (isUpdateError === false) {
      dispatch(fetchAccountDetailsDataByAccountId(selectedAccount.clientId));
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (isUpdateError === false) {
      dispatch({
        type: ENotificationType.Success,
        primaryMessage: '<b>Congratulations.</b><p>Your changes have been saved successfully.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else if (isUpdateError === true) {
      dispatch({
        type: ENotificationType.Danger,
        primaryMessage: '<b>Unfortunately we have encountered a problem.</b><p>Please try again later.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else {
      dispatch({
        type: ENotificationType.Clear,
      });
    }
  }, [isUpdateError]);

  useEffect(() => {
    dispatch(resetIsUpdateError());
  }, [selectedAccount]);

  const handleGoBack = () => {
    updateModalStatus(false);
  };

  const handleOnDiscard = () => {
    dispatch(resetIsUpdateError());
    updateModalStatus(false);
    formRef.current.setIsEdit(false);
    dispatch(fetchAccountDetailsDataByAccountId(selectedAccount.clientId));
  };

  const handleOnDismiss = () => {
    dispatch(resetIsUpdateError());
  };

  const updateAccountDetails = (formData: BusinessOrBillingAddress[]) => {
    const addresses = formData.map((addressData) => omitBy(addressData, isEmpty)) as BusinessOrBillingAddress[];
    dispatch(resetIsUpdateError());
    dispatch(updateAccountDetailsDataByAccountUUID({ id: accountInfo.id, body: { addresses } }));
    GtmManager.dataLayer({
      dataLayer: {
        event: 'save_account_details',
      },
      dataLayerName: 'MyAccount',
    });
  };

  const content = useMemo(() => {
    if (accountSelectError || isError) {
      if (accountSelectError?.status === HttpStatus.Forbidden || status === HttpStatus.Forbidden) {
        return <Forbidden pageTitle="Account details" pageName={EPage.AccountDetails} />;
      }
      return <ErrorComponent pageTitle="Account details" pageName={EPage.AccountDetails} />;
    }
    return (
      <>
        {accountInfo && (
          <>
            <PageHead title="Account details" className="page-head" />
            <Notification onDismiss={handleOnDismiss} />
            <AccountForm
              data-testid="account-details-form"
              accountDetailsInfo={accountInfo}
              onSave={updateAccountDetails}
              onCancel={() => updateModalStatus(true)}
              ref={formRef}
            />
          </>
        )}
      </>
    );
  }, [accountInfo, isError, status, accountSelectError]);

  return (
    <div data-testid="account-details-page">
      {showModal && (
        <ModalBox
          onDiscard={handleOnDiscard}
          onGoBack={handleGoBack}
          title="Discard unsaved changes?"
          message="You've made changes that will be lost if you don't go back and save them."
        />
      )}
      <LoadingWrapper isLoading={isLoading}>
        <BaseTemplate>
          <AccountDetailsWrapper>{content}</AccountDetailsWrapper>
        </BaseTemplate>
      </LoadingWrapper>
    </div>
  );
};

export default AccountDetails;
