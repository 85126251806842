import { PageState, Spinner, SpinnerPropsSize } from '@myob/myob-widgets';

interface LoadingWrapperProps {
  isLoading: boolean;
  spinnerSize?: SpinnerPropsSize;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ isLoading, spinnerSize = 'large', children }) => {
  if (isLoading) {
    return (
      <div data-testid="loadingWrapper">
        <PageState title="Loading" image={<Spinner size={spinnerSize} />} />
      </div>
    );
  }
  return <>{children}</>;
};

export default LoadingWrapper;
