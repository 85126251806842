import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, ButtonRow, Card, Checkbox, Box, Heading, Alert, Text, ReadOnly } from '@myob/myob-widgets';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from 'stores';
import { FormWrapper } from './styles';
import { AccountDetailsInfo, AddressType, BusinessOrBillingAddress } from '../../type';
import { BusinessNumberLabel, MyobLegalEntity } from 'modules/AccountDetails/type';
import { resetIsUpdateError } from '../../reducer';
import LoadingWrapper from 'components/LoadingWrapper';
import AddressForm from './AddressForm';
import { AddressRules } from './constant';
import { auth } from '@my-account/tools';
import { CONTACT_SUPPORT_ROUTE } from 'router';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

type AccountDetailsProps = {
  accountDetailsInfo: AccountDetailsInfo;
  // eslint-disable-next-line no-unused-vars
  onSave?: (formData: BusinessOrBillingAddress[]) => void;
  onCancel: () => void;
  ref?: ForwardedRef<unknown>;
};

export const initState = {
  country: '',
  streetLine1: '',
  streetLine2: '',
  city: '',
  state: '',
  postcode: '',
  addressType: '',
};

const AccountCardHeader = (props: { name: string }) => {
  const { name } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom="lg"
      marginBottom="lg"
      borderBottomStyle="solid"
      borderBottomWidth="thin"
      borderBottomColor="#E5E5E5"
    >
      <Heading level="2" marginBottom="clear">
        {name}
      </Heading>
    </Box>
  );
};

// eslint-disable-next-line react/display-name
const ContactSupportLink = React.memo(() => {
  return (
    <Link
      to={{
        pathname: CONTACT_SUPPORT_ROUTE,
        state: { category: 'Account and subscription', subCategory: 'Change account or contact details' },
      }}
    >
      contact support
    </Link>
  );
});

const AccountForm: React.FC<AccountDetailsProps> = forwardRef(function _AccountForm(
  { accountDetailsInfo, onSave, onCancel },
  ref,
) {
  const businessAddressFormRef = useRef(null);
  const billingAddressFormRef = useRef(null);
  const dispatch: AppThunkDispatch = useDispatch();
  const { accountName, businessNumber, clientId, addresses, myobLegalEntity, primaryContact } = accountDetailsInfo;

  const userID = auth.getUser().userId;
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);
  useEffect(() => {
    if (userID === accountDetailsInfo.primaryContactMyobId) {
      setIsPrimaryContact(true);
    }
  }, [userID]);

  const { isLoading: isUpdating } = useSelector<RootState, Record<string, any>>((state) => state.accountDetails.update);

  const [businessAddress, setBusinessAddress] = useState(
    addresses?.find((address) => address.addressType === AddressType.BUSINESS) || {
      ...initState,
      addressType: AddressType.BUSINESS,
    },
  );
  const [billingAddress, setBillingAddress] = useState(
    addresses?.find((address) => address.addressType === AddressType.BILLING),
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isChecked, setIsChecked] = useState(
    !addresses?.find((address) => address.addressType === AddressType.BILLING),
  );

  useImperativeHandle(ref, () => ({
    setIsEdit,
  }));

  const getBusinessNumberLabel = (legalEntity: MyobLegalEntity): BusinessNumberLabel => {
    if (legalEntity === MyobLegalEntity.NZ) {
      return BusinessNumberLabel.NZ;
    }
    return BusinessNumberLabel.AU;
  };

  const validateData = (value: string, rule: AddressRules): boolean => {
    let isValid = true;
    if (rule.requiredLabel && !value) {
      isValid = false;
    }
    return isValid;
  };

  const validateAddressForm = (addressData: BusinessOrBillingAddress): boolean => {
    const businessRule = businessAddressFormRef.current.validationRules;
    const billingRule = billingAddressFormRef.current?.validationRules;
    const rule = addressData.addressType === AddressType.BUSINESS ? businessRule : billingRule;

    for (const name in addressData) {
      const isValid = validateData(addressData[name], rule[name]);
      if (!isValid) {
        return false;
      }
    }
    return true;
  };

  const handleOnSave = () => {
    if (isChecked) {
      if (!validateAddressForm(businessAddress)) return businessAddressFormRef.current.validateFormData();
    } else {
      if (!validateAddressForm(businessAddress) || !validateAddressForm(billingAddress)) {
        businessAddressFormRef.current.validateFormData();
        billingAddressFormRef.current.validateFormData();
        return;
      }
    }
    handleSave();
  };

  const handleSave = () => {
    onSave([businessAddress, isChecked ? { ...businessAddress, addressType: AddressType.BILLING } : billingAddress]);
  };

  const showCancelAndSave = () => {
    dispatch(resetIsUpdateError());
    setIsEdit(true);
  };

  const handleOnChange = (data: BusinessOrBillingAddress, addressType: AddressType) => {
    addressType === AddressType.BUSINESS ? setBusinessAddress(data) : setBillingAddress(data);
  };

  const handleOnCheck = () => {
    if (isChecked && !billingAddress) setBillingAddress({ ...initState, addressType: AddressType.BILLING });
    setIsChecked(!isChecked);
    setIsSaveDisabled(false);
  };

  return (
    <LoadingWrapper isLoading={isUpdating}>
      <FormWrapper data-testid="account-details-form">
        {!isPrimaryContact && primaryContact && (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">Account owners can edit your business address here.</Text>
              <Text>
                To change your business name, ABN or ACN, your account owner needs to&nbsp;
                <ContactSupportLink />.
              </Text>
              <Text marginBottom="clear">
                {`Your account owner is ${primaryContact.firstname} ${primaryContact.lastname} (`}
                <Text as="a" href={`mailto:${primaryContact.email}`}>
                  {primaryContact.email}
                </Text>
                {')'}
              </Text>
            </Box>
          </Alert>
        )}
        <Box marginBottom="sm">
          <Card
            className="account-form-card"
            size="lg"
            data-testid="business-details-card"
            header={<AccountCardHeader name="Business details" />}
            body={
              <Card.Body
                child={
                  <>
                    {isPrimaryContact && (
                      <Alert tone="info">
                        To change your business name, ABN or ACN,&nbsp;
                        <ContactSupportLink />.
                      </Alert>
                    )}
                    <ReadOnly width="xl" name="accountName" label="Business name" data-testid="account-name">
                      {accountName}
                    </ReadOnly>
                    <ReadOnly
                      className={isEmpty(businessNumber) ? 'empty-business-number' : ''}
                      width="xl"
                      name="businessNumber"
                      label={getBusinessNumberLabel(myobLegalEntity)}
                      data-testid="business-number"
                    >
                      {isEmpty(businessNumber) ? '(No ABN or ACN supplied)' : businessNumber}
                    </ReadOnly>
                    <ReadOnly width="xl" name="clientID" label="Your client ID" data-testid="client-id">
                      {clientId}
                    </ReadOnly>
                  </>
                }
              />
            }
          />
        </Box>

        <Box marginBottom="sm">
          <Card
            className="account-form-card"
            size="lg"
            data-testid="address-details-card"
            header={<AccountCardHeader name="Address details" />}
            body={
              <Card.Body
                child={
                  <>
                    <AddressForm
                      addressInfo={businessAddress}
                      title="Business address"
                      isEdit={isEdit}
                      onChange={(data) => handleOnChange(data, AddressType.BUSINESS)}
                      setIsSaveDisabled={() => setIsSaveDisabled(false)}
                      ref={businessAddressFormRef}
                    >
                      <Checkbox
                        name="Business address checkbox"
                        label="My billing address is the same as my business address"
                        checked={isChecked}
                        disabled={!isEdit}
                        onClick={handleOnCheck}
                      />
                    </AddressForm>

                    {!isChecked && (
                      <AddressForm
                        addressInfo={billingAddress}
                        title="Billing address"
                        isEdit={isEdit}
                        onChange={(data) => handleOnChange(data, AddressType.BILLING)}
                        setIsSaveDisabled={() => setIsSaveDisabled(false)}
                        ref={billingAddressFormRef}
                      />
                    )}
                  </>
                }
              />
            }
          />
        </Box>

        {isPrimaryContact && (
          <ButtonRow>
            {!isEdit && (
              <div data-gtm-id="edit-btn-account-page">
                <ButtonRow>
                  <Button onClick={showCancelAndSave} label="Edit">
                    Edit
                  </Button>
                </ButtonRow>
              </div>
            )}
            {isEdit && (
              <div data-gtm-id="cancel-btn-account-page">
                <ButtonRow>
                  <Button type="secondary" label="Cancel" onClick={onCancel}>
                    Cancel
                  </Button>
                </ButtonRow>
              </div>
            )}
            {isEdit && (
              <div data-gtm-id="save-btn-account-page">
                <ButtonRow>
                  <Button label="Save" disabled={isSaveDisabled} onClick={handleOnSave}>
                    Save
                  </Button>
                </ButtonRow>
              </div>
            )}
          </ButtonRow>
        )}
      </FormWrapper>
    </LoadingWrapper>
  );
});

export default AccountForm;
