import { Button, Card, HelpIcon, PageHead, PageState } from '@myob/myob-widgets';
import somethingWentWrong from 'assets/images/somethingWentWrong.svg';
import { trackClickInErrorPage } from './helper';
import { openInNewTab } from 'helpers/tools';
import { nzConfig, auConfig } from 'config';
import { helper } from '@my-account/tools';
import { Region } from 'modules/AccountDetails/type';

export type Props = {
  pageTitle: string;
  pageName: string;
};

export const ErrorComponent: React.FC<Props> = ({ pageTitle, pageName }) => {
  const helpLink = helper.getRegionFromTimezone() === Region.NZ ? nzConfig.HELP_LINK : auConfig.HELP_LINK;
  const handleClick = () => {
    trackClickInErrorPage(pageName);
    openInNewTab(helpLink);
  };
  return (
    <div data-testid="somethingWentWrong">
      <PageHead title={pageTitle} />
      <Card>
        <PageState
          title="Something went wrong"
          description={[
            'Try reloading this page.',
            <p key="contact support">If the issue persists, contact MYOB support.</p>,
          ]}
          actions={[
            <Button key={1} type="link" icon={<HelpIcon />} onClick={handleClick}>
              Contact our support team
            </Button>,
          ]}
          image={<img src={somethingWentWrong} alt="Something Went Wrong" />}
        />
      </Card>
    </div>
  );
};
