import { useEffect } from 'react';
import { pageTrack } from 'telemetry/pageTrack';
import { useLocation } from 'react-router-dom';
import { isBelongToInternalRoutePath } from 'helpers/tools';

export const usePageView = (): void => {
  const location = useLocation();
  useEffect(() => {
    if (isBelongToInternalRoutePath(location.pathname)) {
      pageTrack(location.pathname);
    }
  }, [location.pathname]);
};
