import { telemetry } from '@my-account/tools';
import { computePageNameFromPath, getMyobVisitorId } from 'helpers/tools';

export const pageTrack = (path: string): void => {
  const pageName = computePageNameFromPath(path);

  telemetry.page(`My Account ${pageName} Page`, {
    title: pageName,
    myobVisitorId: getMyobVisitorId(),
  });
};
