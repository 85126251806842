export interface NotificationState {
  type: ENotificationType;
  primaryMessage: string;
  secondaryMessage?: string;
  dismissAfter?: number;
}

export enum ENotificationType {
  Success = 'SUCCESS_NOTIFICATION',
  Danger = 'DANGER_NOTIFICATION',
  Clear = 'CLEAR_NOTIFICATION',
}

export interface INotificationAction {
  type: ENotificationType;
  primaryMessage: string;
  secondaryMessage: string;
  dismissAfter: number;
}
