import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Route, Switch } from 'react-router-dom';
import AccountDetails from 'modules/AccountDetails';
import ContactDetails from 'modules/ContactDetails';
import CommunicationPreferencesDetails from 'modules/CommunicationPreferencesDetails';
import { usePageView } from 'hooks/usePageView';
import { AccountSelectWrapper, useAccountSelectorState } from '@my-account/account';
import { ThemeProvider, ToastProvider } from '@myob/myob-widgets';
import { productFeatureFlag } from '../configs/FeatureFlags';

export enum InternalRoutePath {
  ManageAccountAccountDetails = '/account/manage-account/account-details',
  ContactDetails = '/account/contact-details',
  CommunicationPreferencesDetails = '/account/communication-preferences',
}

export const InternalRoutePageNameMap = new Map([
  [InternalRoutePath.ManageAccountAccountDetails, 'Account Details'],
  [InternalRoutePath.ContactDetails, 'Contact Details'],
  [InternalRoutePath.CommunicationPreferencesDetails, 'Communication Preferences Details'],
]);

export const ERoutePath = {
  ...InternalRoutePath,
};

export const CONTACT_SUPPORT_ROUTE = '/account/support/contact-support';

const Routes: React.FC = () => {
  usePageView();

  const [{ isSoloAccount }] = useAccountSelectorState();
  const theme = isSoloAccount && productFeatureFlag.isNewUnoDesignEnabled() ? 'solo' : 'classic';

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <CacheProvider
          value={createCache({
            key: 'my-account-account-details',
          })}
        >
          <div className={`my-account-${theme}`}>
            <Switch>
              <Route exact path={ERoutePath.ManageAccountAccountDetails}>
                <AccountSelectWrapper>
                  <AccountDetails />
                </AccountSelectWrapper>
              </Route>
              <Route exact path={ERoutePath.ContactDetails}>
                <ContactDetails />
              </Route>
              <Route exact path={ERoutePath.CommunicationPreferencesDetails}>
                <CommunicationPreferencesDetails />
              </Route>
            </Switch>
          </div>
        </CacheProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default Routes;
