import styled from '@emotion/styled';

export const ContactDetailsWrapper = styled.div`
  .page-head {
    margin-bottom: 2.4rem;
  }
`;

export const EmailUpdateModalWrapper = styled.div`
  .modal-body-loading {
    height: 494px;
  }
  .modal-loading {
    margin-top: 45px;
  }
`;
