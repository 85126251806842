import { Button, Card, HelpIcon, PageState, PageHead } from '@myob/myob-widgets';
import UnauthorizedImg from 'assets/images/noAccess.svg';
import { trackClick } from './helper';
import { openInNewTab } from 'helpers/tools';
import { helper } from '@my-account/tools';
import { auConfig, nzConfig } from 'config';
import { Region } from 'modules/AccountDetails/type';

export type Props = {
  pageTitle: string;
  pageName: string;
};

export const Forbidden: React.FC<Props> = ({ pageTitle, pageName }) => {
  const helpLink = helper.getRegionFromTimezone() === Region.NZ ? nzConfig.HELP_LINK : auConfig.HELP_LINK;
  const handleClick = () => {
    trackClick(pageName);
    openInNewTab(helpLink);
  };
  return (
    <div data-testid="unauthorized-component">
      <PageHead title={pageTitle} />
      <Card>
        <PageState
          title="You will need administrator access to see this page"
          description={[
            'Contact the primary contact of this account to get authorized access. If you need help, get in touch with our support team.',
          ]}
          actions={[
            <Button key={1} type="link" icon={<HelpIcon />} onClick={handleClick}>
              Contact our support team
            </Button>,
          ]}
          image={<img src={UnauthorizedImg} alt="Unauthorized" />}
        />
      </Card>
    </div>
  );
};
