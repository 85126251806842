import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'router';
import store from './stores';

const Root: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <CacheProvider
          value={createCache({
            key: 'my-account-account-details',
          })}
        >
          <Routes />
        </CacheProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default Root;
