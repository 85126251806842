import { Reducer } from 'redux';
import { ENotificationType, INotificationAction, NotificationState } from 'stores/reducers/notification/type';

export const initialNotificationState: NotificationState = {
  type: ENotificationType.Clear,
  primaryMessage: '',
  secondaryMessage: '',
  dismissAfter: 0,
};

export const notification: Reducer<NotificationState, INotificationAction> = (
  state = initialNotificationState,
  action,
) => {
  const { type, primaryMessage, secondaryMessage, dismissAfter } = action;
  switch (type) {
    case ENotificationType.Clear:
      return initialNotificationState;
    case ENotificationType.Danger:
      return {
        type: ENotificationType.Danger,
        primaryMessage,
        secondaryMessage,
      };
    case ENotificationType.Success:
      return {
        type: ENotificationType.Success,
        primaryMessage,
        secondaryMessage,
        dismissAfter,
      };
    default:
      return state;
  }
};

export default notification;
