export interface CommunicationPreferencesDetailsInfo {
  interestedTopics: InterestedTopic[];
  receiveCommunicationsBy: CommunicationMethod[];
}

export enum InterestedTopic {
  TIPS_AND_TRICKS = 'TIPS_AND_TRICKS',
  TRAINING_AND_EVENT = 'TRAINING_AND_EVENT',
  SPECIAL_OFFERS_AND_PROMOTIONS = 'SPECIAL_OFFERS_AND_PROMOTIONS',
  SURVEYS = 'SURVEYS',
}

export enum CommunicationMethod {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PHONE = 'PHONE',
  MAIL = 'MAIL',
}
