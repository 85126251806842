import { AddressType } from '../../type';

export type AddressProperties = {
  [key: string]: Record<string, AddressRules>;
};

export type AddressRules = {
  requiredLabel: string;
  maxLength?: number;
  errorMessage?: string;
  label: string;
};
export const addressProperties: AddressProperties = {
  [AddressType.BUSINESS]: {
    streetLine1: {
      requiredLabel: 'This field is required',
      maxLength: 200,
      errorMessage: 'Business address is required.',
      label: 'Business address',
    },
    city: {
      requiredLabel: 'This field is required',
      maxLength: 40,
      errorMessage: 'City/Suburb is required.',
      label: 'City / Suburb',
    },
    country: {
      requiredLabel: 'This field is required',
      errorMessage: 'Country is required.',
      label: 'Country',
    },
    streetLine2: {
      requiredLabel: '',
      maxLength: 100,
      label: 'Business address line 2',
    },
    state: {
      requiredLabel: 'This field is required',
      maxLength: 100,
      errorMessage: 'State is required.',
      label: 'State',
    },
    postcode: {
      requiredLabel: 'This field is required',
      maxLength: 30,
      errorMessage: 'Postcode is required.',
      label: 'Postcode',
    },
    addressType: {
      requiredLabel: 'This field is required',
      label: '',
    },
  },
  [AddressType.BILLING]: {
    streetLine1: {
      requiredLabel: 'This field is required',
      maxLength: 200,
      errorMessage: 'Billing address is required.',
      label: 'Billing address',
    },
    city: {
      requiredLabel: 'This field is required',
      maxLength: 40,
      errorMessage: 'City/Suburb is required.',
      label: 'City / Suburb',
    },
    country: {
      requiredLabel: 'This field is required',
      errorMessage: 'Country is required.',
      label: 'Country',
    },
    streetLine2: {
      requiredLabel: '',
      maxLength: 100,
      label: 'Billing address line 2',
    },
    state: {
      requiredLabel: 'This field is required',
      maxLength: 100,
      errorMessage: 'State is required.',
      label: 'State',
    },
    postcode: {
      requiredLabel: 'This field is required',
      maxLength: 30,
      errorMessage: 'Postcode is required.',
      label: 'Postcode',
    },
    addressType: {
      requiredLabel: 'This field is required',
      label: '',
    },
  },
};
