import styled from '@emotion/styled';
import { colors, mediaQueries } from 'styles/variables';

export const FormWrapper = styled.div`
  .form-container {
    ${mediaQueries.specificUp} {
      width: 40rem;
    }
  }
  .form-input-container {
    ${mediaQueries.specificUp} {
      width: 100%;

      div:first-of-type {
        ${mediaQueries.specificUp} {
          flex-basis: 16rem;
        }
      }
      div:last-of-type {
        ${mediaQueries.specificUp} {
          flex-basis: 23rem;
        }
      }
    }
  }
`;

export const ShortFormItemWrapper = styled.div`
  display: table;
  margin-bottom: 1.6rem;

  .short-form-input-container:last-child {
    padding-left: 1.6rem;
  }

  .short-form-input-container {
    display: table-cell;

    div:first-of-type {
      ${mediaQueries.specificUp} {
        flex-basis: 16rem;
      }
    }
    div:last-of-type {
      ${mediaQueries.specificUp} {
        flex-basis: 23rem;
      }
    }
  }
`;

export const CardTitleWrapper = styled.h2`
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
`;

export const CardContainerWrapper = styled.div`
  margin-top: 24px;
  display: block;
  justify-content: center;
  flex-direction: column;

  ${mediaQueries.mediumUp} {
    flex-direction: row;
  }
`;

export const CardWrapper = styled.div`
  margin-bottom: 3rem;
`;
