import { ContactDetailsInfo } from '../type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchContactDetails } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type GetContactDetailsState = {
  isLoading: boolean;
  data: ContactDetailsInfo;
  isError: boolean;
  error: ResponseError;
};

export const initialState: GetContactDetailsState = {
  isLoading: false,
  data: null,
  isError: false,
  error: {
    status: null,
    message: null,
  },
};

export const fetchContactDetailsAsync = createAsyncThunk<ContactDetailsInfo, void, { rejectValue: ResponseError }>(
  'contact/fetchContactDetails',
  async (_, { rejectWithValue }) => {
    try {
      return await fetchContactDetails();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'get-contact-details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContactDetailsAsync.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.data = null;
      state.error = { status: null, message: null };
    });
    builder.addCase(fetchContactDetailsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
      state.error = { status: null, message: null };
    });
    builder.addCase(fetchContactDetailsAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.data = null;
      state.error = action.payload;
    });
  },
});

export const getContactReducer = slice.reducer;
