import { ResponseError } from '../../../helpers/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateEmailDetails, UpdateEmailDetailsSchema } from '../../../helpers/api';

export type UpdateEmailDetailsState = {
  isLoading: boolean;
  data: null;
  isError: boolean | null;
  error: ResponseError;
};

export const initialState: UpdateEmailDetailsState = {
  isLoading: false,
  data: null,
  isError: null,
  error: {
    status: null,
    message: null,
  },
};

export const updateEmailDetailsAsync = createAsyncThunk<
  Response,
  UpdateEmailDetailsSchema,
  { rejectValue: ResponseError }
>('contact/updateEmailDetails', async (data, { rejectWithValue }) => {
  try {
    await updateEmailDetails(data);
    return;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const slice = createSlice({
  name: 'update-email-details',
  initialState,
  reducers: {
    resetIsUpdateEmailError(state) {
      state.isError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateEmailDetailsAsync.pending, (state) => {
      state.isLoading = true;
      state.error = { status: null, message: null };
    });
    builder.addCase(updateEmailDetailsAsync.fulfilled, (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = { status: null, message: null };
    });
    builder.addCase(updateEmailDetailsAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    });
  },
});

export const updateEmailReducer = slice.reducer;
export const { resetIsUpdateEmailError } = slice.actions;
