import { countryList } from '../../../../constants/CountryList';
import { countryStateMap } from '../../../../constants/CountryStates';
import { Heading, Input, Select } from '@myob/myob-widgets';
import { ShortFormItemWrapper } from './styles';
import { BusinessOrBillingAddress, Country } from '../../type';
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { addressProperties } from './constant';

type AddressDataProps = {
  addressInfo: BusinessOrBillingAddress;
  title: string;
  isEdit: boolean;
  onChange: (data: any) => void;
  setIsSaveDisabled: () => void;
  ref?: ForwardedRef<unknown>;
};

const initState = {
  errors: {
    streetLine1: {
      isValid: false,
      value: '',
    },
    city: {
      isValid: false,
      value: '',
    },
    country: {
      isValid: false,
      value: '',
    },
    streetLine2: {
      isValid: false,
      value: '',
    },
    state: {
      isValid: false,
      value: '',
    },
    postcode: {
      isValid: false,
      value: '',
    },
    addressType: {
      isValid: true,
      value: '',
    },
  },
};

const countryOptions = () => {
  const countryOptions = countryList.map((country) => <Select.Option key={country} value={country} label={country} />);

  return (
    <>
      <Select.Option key="placeholder" value="" label="" hidden />
      {countryOptions}
    </>
  );
};

const stateOptions = (country) => {
  const stateOptions = countryStateMap[country].map((state) => (
    <Select.Option key={state} value={state} label={state} />
  ));

  return (
    <>
      <Select.Option key="placeholder" value="" label="" hidden />
      {stateOptions}
    </>
  );
};

const AddressForm: React.FC<AddressDataProps> = forwardRef(function _AddressForm(
  { addressInfo, title, isEdit, onChange, setIsSaveDisabled, children },
  ref,
) {
  const [addressFormData, setAddressFormData] = useState<BusinessOrBillingAddress>(addressInfo);
  const [errors, setErrors] = useState(initState.errors);
  const [validationRules, setValidationRules] = useState(addressProperties[addressInfo.addressType]);

  useImperativeHandle(ref, () => ({
    validateFormData,
    validationRules,
  }));

  useEffect(() => {
    resetErrors();
    if (!addressFormData.country || addressFormData.country === Country.AU || addressFormData.country === Country.NZ) {
      setObjectState(setValidationRules, {
        postcode: {
          ...validationRules.postcode,
          label: 'Postcode',
          requiredLabel: 'This field is required',
        },
      });
    } else {
      setObjectState(setValidationRules, {
        postcode: {
          ...validationRules.postcode,
          label: 'Postcode / Zip code',
          requiredLabel: '',
        },
      });
    }

    if (addressFormData.country === Country.AU) {
      setObjectState(setValidationRules, {
        state: { ...validationRules.state, requiredLabel: 'This field is required' },
      });
    } else {
      setObjectState(setValidationRules, { state: { ...validationRules.state, requiredLabel: '' } });
    }
  }, [addressFormData.country]);

  useEffect(() => {
    onChange(addressFormData);
  }, [addressFormData]);

  const resetErrors = () => {
    Object.keys(errors).forEach((item) => {
      item !== 'addressType' &&
        item !== 'country' &&
        setObjectState(setErrors, { [item]: { isValid: false, value: '' } });
    });
  };

  const setObjectState = (setState: React.Dispatch<React.SetStateAction<any>>, data: any) => {
    setState((preState) => ({
      ...preState,
      ...data,
    }));
  };

  const validateData = (name: string, value: string | null) => {
    const rule = validationRules[name];
    let isValid = true;
    let errMsg = '';
    if (rule.requiredLabel && !value) {
      isValid = false;
      errMsg = rule.errorMessage;
    }
    return {
      isValid,
      errMsg,
    };
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errMsg, isValid } = validateData(name, value);

    setObjectState(setAddressFormData, { [name]: value });
    setObjectState(setErrors, { [name]: { isValid, value: errMsg } });
    setIsSaveDisabled();
  };

  const handleOnBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errMsg, isValid } = validateData(name, value);
    setObjectState(setErrors, { [name]: { isValid, value: errMsg } });
  };

  const handleCountryOnChange = (e) => {
    handleOnChange(e);
    setObjectState(setAddressFormData, { state: '' });
    resetErrors();
  };

  const handleOnPostCodeChange = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z\d\s]/gi, '');
    handleOnChange(e);
  };

  const validateFormData = () => {
    for (const name in addressFormData) {
      const value = addressFormData[name];
      const { errMsg, isValid } = validateData(name, value);
      setObjectState(setErrors, { [name]: { isValid, value: errMsg } });
    }
  };

  return (
    <>
      <Heading level="3" marginTop="lg">
        {title}
      </Heading>
      <Select
        name="country"
        label={validationRules.country.label}
        width="xl"
        disabled={!isEdit}
        errorMessage={errors.country.value}
        requiredLabel={validationRules.country.requiredLabel}
        value={addressFormData.country}
        onChange={handleCountryOnChange}
        onBlur={handleOnBlur}
      >
        {countryOptions()}
      </Select>
      <Input
        containerClassName="form-input-container"
        width="xl"
        name="streetLine1"
        label={validationRules.streetLine1.label}
        value={addressFormData.streetLine1}
        disabled={!isEdit}
        requiredLabel={validationRules.streetLine1.requiredLabel}
        errorMessage={errors.streetLine1.value}
        onChange={handleOnChange}
        maxLength={validationRules.streetLine1.maxLength}
        onBlur={handleOnBlur}
        data-testid="streetLine1"
      />
      <Input
        containerClassName="form-input-container"
        width="xl"
        name="streetLine2"
        label={validationRules.streetLine2.label}
        value={addressFormData.streetLine2}
        disabled={!isEdit}
        requiredLabel={validationRules.streetLine2.requiredLabel}
        errorMessage={errors.streetLine2.value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        maxLength={validationRules.streetLine2.maxLength}
      />
      <Input
        containerClassName="form-input-container"
        width="xl"
        name="city"
        label={validationRules.city.label}
        value={addressFormData.city}
        disabled={!isEdit}
        requiredLabel={validationRules.city.requiredLabel}
        errorMessage={errors.city.value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        maxLength={validationRules.city.maxLength}
      />

      <ShortFormItemWrapper>
        {addressFormData.country === Country.AU && (
          <Select
            name="state"
            label={validationRules.state?.label}
            width="sm"
            containerClassName="short-form-input-container"
            disabled={!isEdit}
            requiredLabel={validationRules.state?.requiredLabel}
            value={addressFormData.state}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            errorMessage={errors.state.value}
          >
            {stateOptions(addressFormData.country)}
          </Select>
        )}
        <Input
          containerClassName="short-form-input-container"
          width="sm"
          name="postcode"
          label={validationRules.postcode.label}
          value={addressFormData.postcode}
          disabled={!isEdit}
          requiredLabel={validationRules.postcode.requiredLabel}
          errorMessage={errors.postcode.value}
          onChange={handleOnPostCodeChange}
          onBlur={handleOnBlur}
          maxLength={validationRules.postcode.maxLength}
        />
      </ShortFormItemWrapper>
      {children}
    </>
  );
});

export default AddressForm;
