import styled from '@emotion/styled';
import { mediaQueries } from 'styles/variables';

export const FormWrapper = styled.div`
  .account-form-card {
    border-radius: 12px;

    .empty-business-number {
      text-fill-color: #a5a5a7;
    }
  }

  .form-container {
    ${mediaQueries.specificUp} {
      width: 40rem;
    }
  }
  .form-input-container {
    ${mediaQueries.specificUp} {
      width: 100%;

      div:first-of-type {
        ${mediaQueries.specificUp} {
          flex-basis: 16rem;
        }
      }
      div:last-of-type {
        ${mediaQueries.specificUp} {
          flex-basis: 23rem;
        }
      }
    }
  }
`;

export const ContactDetailWrapper = styled.div`
  .form-container {
    ${mediaQueries.specificUp} {
      width: 40rem;
    }
  }
  .form-input-container {
    ${mediaQueries.specificUp} {
      width: 100%;
    }
  }
`;

export const ShortFormItemWrapper = styled.div`
  display: flex;

  .short-form-input-container:first-of-type {
    width: 184px;
    padding-right: 1.6rem;
  }

  .short-form-input-container {
    div:first-of-type {
      ${mediaQueries.specificUp} {
        flex-basis: 16rem;
      }
    }
    div:last-of-type {
      ${mediaQueries.specificUp} {
        flex-basis: 23rem;
      }
    }
  }
`;
