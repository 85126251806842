import styled from '@emotion/styled';

export const CommunicationPreferencesDetailsWrapper = styled.div`
  .page-head {
    margin-bottom: 2.4rem;
  }

  .myob-surveys {
    margin-bottom: 0;
  }

  .btn-row {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }

  .checkbox {
    margin-bottom: 16px;
  }

  margin-bottom: 3rem;
`;
