import { AccountDetailsInfo } from './type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAccountDetailsByAccountId, UpdateAccountDetails, updateAccountDetailsByAccountUUID } from 'helpers/api';
import { ResponseError } from 'helpers/request';
import { gaPushEvent, trackViewEventInAccountDetails } from '../../helpers/googleAnalytics';
import { Account } from '@my-account/account';

export type AccountDetailsState = {
  fetch: {
    isLoading: boolean;
    data: AccountDetailsInfo;
    isError: boolean;
    error: ResponseError;
  };
  update: {
    isLoading: boolean;
    data: AccountDetailsInfo;
    isError: boolean;
    error: ResponseError;
  };
};

export const initialState: AccountDetailsState = {
  fetch: {
    isLoading: false,
    data: null,
    isError: false,
    error: {
      status: null,
      message: null,
    },
  },
  update: {
    isLoading: false,
    data: null,
    isError: null,
    error: {
      status: null,
      message: null,
    },
  },
};

export const fetchAccountDetailsDataByAccountId = createAsyncThunk<
  AccountDetailsInfo,
  Account['clientId'],
  { rejectValue: ResponseError }
>('account/fetchAccountDetailsById', async (accountId, { rejectWithValue }) => {
  try {
    const accountDetailsInfo = await fetchAccountDetailsByAccountId(accountId);

    trackViewEventInAccountDetails('granted');
    gaPushEvent({
      event: 'contentViewed',
      elementId: 'Granted',
    });
    return accountDetailsInfo;
  } catch (e) {
    const { message, status } = e;
    trackViewEventInAccountDetails('denied', message);
    status &&
      gaPushEvent({
        event: 'contentViewed',
        elementId: `Denied_${status}`,
      });
    return rejectWithValue(e);
  }
});

export const updateAccountDetailsDataByAccountUUID = createAsyncThunk<
  Response,
  UpdateAccountDetails,
  { rejectValue: ResponseError }
>('account/updateAccountDetailsByUUID', async (data, thunkApi) => {
  try {
    await updateAccountDetailsByAccountUUID(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const slice = createSlice({
  name: 'account-details',
  initialState,
  reducers: {
    resetIsUpdateError(state) {
      state.update.isError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountDetailsDataByAccountId.pending, (state) => {
      state.fetch.isLoading = true;
      state.fetch.isError = false;
      state.fetch.data = null;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchAccountDetailsDataByAccountId.fulfilled, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = false;
      state.fetch.data = action.payload;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchAccountDetailsDataByAccountId.rejected, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = true;
      state.fetch.data = null;
      state.fetch.error = action.payload;
    });

    builder.addCase(updateAccountDetailsDataByAccountUUID.pending, (state) => {
      state.update.isLoading = true;
    });
    builder.addCase(updateAccountDetailsDataByAccountUUID.fulfilled, (state) => {
      state.update.isLoading = false;
      state.update.isError = false;
    });
    builder.addCase(updateAccountDetailsDataByAccountUUID.rejected, (state) => {
      state.update.isLoading = false;
      state.update.isError = true;
    });
  },
});
export const reducer = slice.reducer;

export const { resetIsUpdateError } = slice.actions;
