export const countryStateMap = {
  Australia: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
  'New Zealand': ['CANTERBURY'],
  Malaysia: [
    'Johor',
    'Kedah',
    'Kelantan',
    'Melaka',
    'N.Sembilan',
    'P.Pinang',
    'Pahang',
    'Perak',
    'Perlis',
    'Sabah',
    'Sarawak',
    'Selangor',
    'Terengganu',
    'WP',
  ],
  'Hong Kong': ['HKI', 'KLN'],
  Vanuatu: ['PMB'],
};
