import { Button, ButtonRow, Modal } from '@myob/myob-widgets';

type ModalProps = {
  onDiscard: () => void;
  onGoBack: () => void;
  title?: string;
  message?: string;
};

const ModalBox: React.FC<ModalProps> = ({ onDiscard, onGoBack, ...props }) => {
  const { title, message } = props;
  const onCancelHandle = () => {
    onDiscard();
  };
  const onGoBackHandle = () => {
    onGoBack();
  };

  return (
    <Modal title={title} onCancel={onGoBackHandle}>
      <Modal.Body className="modal-body">{message}</Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button type="secondary" onClick={onGoBackHandle}>
            Go back
          </Button>
          <Button type="delete" onClick={onCancelHandle}>
            Discard
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBox;
