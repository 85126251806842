import { AnyAction } from 'redux';
import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { reducer as accountDetailsReducer } from 'modules/AccountDetails/reducer';
import { getContactReducer } from 'modules/ContactDetails/reducers/getContact';
import { updateContactReducer } from 'modules/ContactDetails/reducers/updateContact';
import { notification } from 'stores/reducers/notification';
import { getCommunicationPreferencesReducer } from 'modules/CommunicationPreferencesDetails/reducers/getCommunicationPreferences';
import { updateCommunicationPreferencesReducer } from 'modules/CommunicationPreferencesDetails/reducers/updateCommunicationPreferences';
import { updateEmailReducer } from '../modules/ContactDetails/reducers/updateEmail';
export type RootState = ReturnType<typeof store.getState>;

const store = configureStore({
  reducer: {
    accountDetails: accountDetailsReducer,
    getContact: getContactReducer,
    updateContact: updateContactReducer,
    notification,
    getCommunicationPreferences: getCommunicationPreferencesReducer,
    updateCommunicationPreferences: updateCommunicationPreferencesReducer,
    updateEmail: updateEmailReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, Record<string, unknown>, AnyAction>;

export default store;
