import { telemetry, ga, DataLayerObject } from '@my-account/tools';
import { ETelemetryActionsAndLabels, ETelemetryEventNames } from 'telemetry/type';
import upperFirst from 'lodash/upperFirst';

export const trackViewEventInAccountDetails = (permission: 'granted' | 'denied', reason?: string): void => {
  telemetry.track(ETelemetryEventNames.AccountDetails, {
    action: ETelemetryActionsAndLabels.View,
    label: ETelemetryActionsAndLabels.View,
    viewData: {
      permission,
      reason,
    },
  });
};

export const trackClickEventInAccountDetails = (buttonName: string): void => {
  telemetry.track(ETelemetryEventNames.AccountDetails, {
    action: ETelemetryActionsAndLabels.Click,
    label: ETelemetryActionsAndLabels.Click,
    clickData: {
      buttonName: upperFirst(buttonName),
    },
  });
};

export const gaPushEvent = (dataLayerObject: DataLayerObject): void => {
  ga.push(dataLayerObject);
};
