import { Input, Card, ButtonRow, Button, Heading, Popover, HelpIcon } from '@myob/myob-widgets';
import { CardContainerWrapper, CardWrapper } from './styles';
import { ContactDetailsInfo } from '../../type';
import { useSelector, useDispatch } from 'react-redux';
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { CONTACT_FORM_PROPERTIES } from '../constants';
import { openInNewTab, useSyncCallback } from '../../../../helpers/tools';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import { AppThunkDispatch, RootState } from '../../../../stores';
import { UpdateContactDetailsState, resetIsUpdateError } from '../../reducers/updateContact';
import { GtmManager, helper } from '@my-account/tools';
import { Region } from 'modules/AccountDetails/type';
import { auConfig, nzConfig } from 'config';
import { ContactDetailWrapper } from 'modules/AccountDetails/components/AccountForm/styles';
import { useAccountSelectorState } from '@my-account/account';

type ContactDetailsProps = {
  contactInfo: ContactDetailsInfo;
  // eslint-disable-next-line no-unused-vars
  onSave?: (contactFormData: ContactDetailsInfo) => void;
  onCancel?: () => void;
  ref?: ForwardedRef<unknown>;
};

const initState = {
  errors: {
    firstname: {
      isValid: false,
      value: '',
    },
    lastname: {
      isValid: false,
      value: '',
    },
    phone: {
      isValid: false,
      value: '',
    },
    email: {
      isValid: true,
      value: '',
    },
  },
};

const ContactForm: React.FC<ContactDetailsProps> = forwardRef(function _ContactForm(
  { contactInfo, onSave, onCancel },
  ref,
) {
  const dispatch: AppThunkDispatch = useDispatch();
  const [contactFormData, setContactFormData] = useState<ContactDetailsInfo>(contactInfo);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errors, setErrors] = useState(initState.errors);
  const myMYOBLink = helper.getRegionFromTimezone() === Region.NZ ? nzConfig.SIGN_IN_MY_MYOB : auConfig.SIGN_IN_MY_MYOB;

  const { isLoading: isUpdating } = useSelector<RootState, UpdateContactDetailsState>((state) => state.updateContact);
  const [{ isSoloAccount }] = useAccountSelectorState();

  useImperativeHandle(ref, () => ({
    setIsEdit,
  }));

  const showCancelAndSave = () => {
    dispatch(resetIsUpdateError());
    setIsEdit(true);
  };

  const setObjectState = (setState: React.Dispatch<React.SetStateAction<any>>, data: any) => {
    setState((preState) => ({
      ...preState,
      ...data,
    }));
  };

  const validateForm = (name: string, value: string) => {
    const rule = CONTACT_FORM_PROPERTIES[name];
    if (
      (rule?.requiredLabel && !value) ||
      (rule?.minLength && value.length < rule.minLength) ||
      (rule?.reg && !new RegExp(rule.reg).test(value))
    ) {
      if (name === 'phone') {
        if (value.split('')[0] !== '+') {
          setErrors((prevState) => ({
            ...prevState,
            [name]: { isValid: false, value: 'Include your country code. For example, +61.' },
          }));
          return;
        }
      }
      setErrors((prevState) => ({ ...prevState, [name]: { isValid: false, value: rule.errorMessage } }));
    } else {
      setErrors((prevState) => ({ ...prevState, [name]: { isValid: true, value: '' } }));
    }
  };

  const handleOnNameChange = (e) => {
    e.target.value = e.target.value
      .split('')
      .filter((item) => item.charCodeAt() >= 32 && item.charCodeAt() <= 126)
      .join('');
    handleOnChange(e);
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validateForm(name, value);
    setObjectState(setContactFormData, { [name]: value });
    setIsSaveDisabled(false);
  };

  const handleOnBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    validateForm(name, value);
    setObjectState(setContactFormData, { [name]: value });
  };

  const handleOnPhoneNumberChange = (e) => {
    e.target.value = e.target.value.replace(/[^\+|\d]/g, '');
    handleOnChange(e);
  };

  const handleOnSave = () => {
    for (const key in contactFormData) {
      validateForm(key, contactFormData[key]);
    }
    GtmManager.dataLayer({
      dataLayer: {
        event: 'save_contact_details',
      },
      dataLayerName: 'MyAccount',
    });
    saveData();
  };

  const saveData = useSyncCallback(() => {
    const isValid = Object.values(errors).filter((item) => !item.isValid).length === 0;
    isValid && onSave(contactFormData);
  });

  const PopoverBody = () => (
    <div>
      <p style={{ margin: '0' }}>
        To change your email,{' '}
        <Button variant="link" tone="brand" onClick={() => openInNewTab(myMYOBLink)}>
          sign into my.MYOB
        </Button>
      </p>
    </div>
  );

  const EmailPopover = () => (
    <Popover body={<Popover.Body child={<PopoverBody />} />} closeOnOuterAction preferPlace="above">
      <HelpIcon color="brand" style={{ width: '18px', cursor: 'pointer' }} data-testid="popover-icon" />
    </Popover>
  );

  const DetailedCardHeader: React.FC<{ isPersonalDetail?: boolean }> = (props) => (
    <Heading level="2" style={{ marginBottom: '12px', fontWeight: '500' }}>
      {props.isPersonalDetail ? 'Personal details' : 'Contact details'}
    </Heading>
  );

  const personalDetailedCardBody = () => (
    <CardContainerWrapper>
      <Input
        containerClassName="form-input-container"
        width="md"
        name="firstname"
        label="First name"
        maxLength={CONTACT_FORM_PROPERTIES.firstname.maxLength}
        value={contactFormData.firstname}
        onChange={handleOnNameChange}
        onBlur={handleOnBlur}
        disabled={!isEdit}
        requiredLabel={CONTACT_FORM_PROPERTIES.firstname.requiredLabel}
        errorMessage={errors.firstname.value}
      />
      <Input
        containerClassName="form-input-container"
        width="md"
        name="lastname"
        label="Last name"
        maxLength={CONTACT_FORM_PROPERTIES.lastname.maxLength}
        value={contactFormData.lastname}
        onChange={handleOnNameChange}
        onBlur={handleOnBlur}
        disabled={!isEdit}
        requiredLabel={CONTACT_FORM_PROPERTIES.lastname.requiredLabel}
        errorMessage={errors.lastname.value}
      />
    </CardContainerWrapper>
  );

  const contactDetailedCardBody = () => (
    <CardContainerWrapper>
      <Input
        containerClassName="form-input-container"
        width="md"
        name="phone"
        label="Phone number"
        maxLength={CONTACT_FORM_PROPERTIES.phone.maxLength}
        value={contactFormData.phone}
        onChange={handleOnPhoneNumberChange}
        onBlur={handleOnBlur}
        disabled={!isEdit}
        requiredLabel={CONTACT_FORM_PROPERTIES.phone.requiredLabel}
        errorMessage={errors.phone.value}
        placeholder={isEdit ? '+61412345678' : ''}
      />
      <Input
        containerClassName="form-input-container"
        width="md"
        name="email"
        label="Email"
        labelAccessory={!isSoloAccount ? <EmailPopover /> : null}
        value={contactFormData.email}
        disabled
        requiredLabel={CONTACT_FORM_PROPERTIES.email.requiredLabel}
        errorMessage={errors.email.value}
      />
    </CardContainerWrapper>
  );

  return (
    <ContactDetailWrapper data-testid="contact-details-form">
      <LoadingWrapper isLoading={isUpdating}>
        <CardWrapper>
          <Card
            header={<Card.Header child={<DetailedCardHeader isPersonalDetail />} />}
            body={<Card.Body child={personalDetailedCardBody()} />}
            size="lg"
            data-testid="personal-details-card"
          />
        </CardWrapper>
        <CardWrapper>
          <Card
            data-testid="contact-details-card"
            size="lg"
            header={<Card.Header child={<DetailedCardHeader />} />}
            body={<Card.Body child={contactDetailedCardBody()} />}
          />
        </CardWrapper>

        {
          <ButtonRow>
            {!isEdit && (
              <div data-gtm-id="edit-btn-contact-page">
                <Button onClick={showCancelAndSave} label="Edit">
                  Edit
                </Button>
              </div>
            )}
            {isEdit && (
              <div data-gtm-id="cancel-btn-contact-page">
                <Button type="secondary" label="Cancel" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            )}
            {isEdit && (
              <div data-gtm-id="save-btn-contact-page">
                <Button label="Save" disabled={isSaveDisabled} onClick={handleOnSave}>
                  Save
                </Button>
              </div>
            )}
          </ButtonRow>
        }
      </LoadingWrapper>
    </ContactDetailWrapper>
  );
});

export default ContactForm;
