import { ContactDetailsInfo } from 'modules/ContactDetails/type';

export interface BusinessOrBillingAddress {
  streetLine1: string;
  city: string;
  country: string;
  streetLine2?: string;
  state?: string;
  postcode?: string;
  addressType: AddressType;
}

export interface AccountDetailsInfo {
  id: string;
  clientId: string;
  accountName: string;
  businessNumber: string;
  myobLegalEntity: MyobLegalEntity;
  primaryContactMyobId: string;
  primaryContact?: ContactDetailsInfo;
  addresses: BusinessOrBillingAddress[];
}

export enum Region {
  AU = 'AU',
  NZ = 'NZ',
}

export enum Country {
  AU = 'Australia',
  NZ = 'New Zealand',
}

export enum BusinessNumberLabel {
  AU = 'Your business ABN or ACN',
  NZ = 'Your business NZBN',
}

export enum MyobLegalEntity {
  AU = 'MYOB Australia Pty Ltd',
  NZ = 'MYOB NZ Limited',
}

export enum AddressType {
  BUSINESS = 'business',
  BILLING = 'billing',
}
