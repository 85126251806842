import {
  Alert,
  Button,
  ButtonRow,
  Input,
  Modal,
  PageState,
  ReadOnly,
  Separator,
  Spinner,
  Text,
} from '@myob/myob-widgets';
import { EMAIL_FORM_PROPERTIES } from '../constants';
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { EmailDetailsInfo } from '../../type';
import { useSyncCallback } from '../../../../helpers/tools';
import { EmailUpdateModalWrapper } from '../../styles';

type ModalProps = {
  onUpdateEmail: (emailFormData: EmailDetailsInfo) => void;
  onCancel: () => void;
  title?: string;
  currentEmail: string;
  ref?: ForwardedRef<unknown>;
  isLoading: boolean;
};
const initState = {
  errors: {
    newEmail: {
      isValid: false,
      value: '',
    },
    confirmEmail: {
      isValid: false,
      value: '',
    },
  },
};

const EmailForm: React.FC<ModalProps> = forwardRef(function _EmailForm({ onUpdateEmail, onCancel, ...props }, ref) {
  const { title, currentEmail, isLoading } = props;
  const emailInfo: EmailDetailsInfo = {
    newEmail: '',
    confirmEmail: '',
  };
  const [emailFormData, setEmailFormData] = useState<EmailDetailsInfo>(emailInfo);
  const [errors, setErrors] = useState(initState.errors);
  const [errorDetail, setErrorDetail] = useState({ isError: false, errorMessage: '' });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid = Object.values(errors).every((error) => error.isValid);
    setIsFormValid(isValid);
  }, [errors]);

  const onSaveEmailHandle = () => {
    for (const key in emailFormData) {
      validateForm(key, emailFormData[key]);
    }
    saveData();
  };

  useImperativeHandle(ref, () => ({
    setErrorDetail,
  }));

  const saveData = useSyncCallback(() => {
    const isValid = Object.values(errors).filter((item) => !item.isValid).length === 0;
    const isValidNewEmail = !isExistingEmail(currentEmail, emailFormData.newEmail);

    if (!isValidNewEmail) {
      setErrorDetail({ isError: true, errorMessage: 'New email cannot be the same as your current email.' });
    }
    isValid && isValidNewEmail && onUpdateEmail(emailFormData);
  });

  const isExistingEmail = (currentEmail, newEmail) => {
    return currentEmail == newEmail;
  };

  const onCancelUpdateEmailHandle = () => {
    onCancel();
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validateForm(name, value);
    setObjectState(setEmailFormData, { [name]: value });
  };

  const setObjectState = (setState: React.Dispatch<React.SetStateAction<any>>, data: any) => {
    setState((preState) => ({
      ...preState,
      ...data,
    }));
  };

  const validateForm = (name: string, value: string) => {
    const rule = EMAIL_FORM_PROPERTIES[name];
    if (rule?.requiredLabel && !value) {
      setErrors((prevState) => ({ ...prevState, [name]: { isValid: false, value: rule.errorMessage } }));
    } else if (rule?.reg && !new RegExp(rule.reg).test(value)) {
      setErrors((prevState) => ({ ...prevState, [name]: { isValid: false, value: rule.invalidEmailMessage } }));
    } else {
      setErrors((prevState) => ({ ...prevState, [name]: { isValid: true, value: '' } }));
      validateMatchEmail(name, value);
    }
  };

  const validateMatchEmail = (name: string, value: string) => {
    const isConfirmEmailMismatch =
      (name === 'confirmEmail' && value !== emailFormData.newEmail && emailFormData.newEmail) ||
      (name === 'newEmail' && value !== emailFormData.confirmEmail && emailFormData.confirmEmail);

    setErrors((prevState) => ({
      ...prevState,
      confirmEmail: {
        isValid: !isConfirmEmailMismatch,
        value: isConfirmEmailMismatch ? 'Emails do not match' : '',
      },
    }));
  };

  return (
    <Modal title={title} size="small" onCancel={onCancelUpdateEmailHandle} canClose={!isLoading}>
      {isLoading ? (
        <EmailUpdateModalWrapper>
          <Modal.Body className="modal-body-loading">
            <PageState className="modal-loading" title="Loading" image={<Spinner />} />
          </Modal.Body>
        </EmailUpdateModalWrapper>
      ) : (
        <>
          <Modal.Body className="modal-body">
            {errorDetail.isError && <Alert tone="danger">{errorDetail.errorMessage}</Alert>}
            <ReadOnly name="Current email" label="Current email">
              {currentEmail}
            </ReadOnly>
            <Separator />
            <Input
              containerClassName="form-input-container"
              name="newEmail"
              label="New email"
              value={emailFormData.newEmail}
              onChange={handleOnChange}
              requiredLabel={EMAIL_FORM_PROPERTIES.newEmail.requiredLabel}
              errorMessage={errors.newEmail.value}
            />
            <Input
              containerClassName="form-input-container"
              name="confirmEmail"
              label="Confirm email"
              value={emailFormData.confirmEmail}
              onChange={handleOnChange}
              requiredLabel={EMAIL_FORM_PROPERTIES.confirmEmail.requiredLabel}
              errorMessage={errors.confirmEmail.value}
            />
            <Text>
              Use this email to sign into Solo and manage your subscription. We’ll send your Solo bills and account
              updates to this email.
            </Text>
            <Text>When you save your new email, you’ll need to sign into Solo again.</Text>
          </Modal.Body>
          <Modal.Footer>
            <ButtonRow className="email-form-footer">
              <Button tone="neutral" onClick={onCancelUpdateEmailHandle}>
                Cancel
              </Button>
              <Button tone="success" onClick={onSaveEmailHandle} disabled={!isFormValid}>
                Save
              </Button>
            </ButtonRow>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
});

export default EmailForm;
