import includes from 'lodash/includes';
import { ERoutePath, InternalRoutePath, InternalRoutePageNameMap } from 'router';
import { useCallback, useEffect, useState } from 'react';
import { auth, telemetry } from '@my-account/tools';
import Cookies from 'js-cookie';
import { matchPath } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const splitPathAndQueryString = (path: string): { path: string; queryString?: string; shouldSkip: boolean } => {
  const allRouterPath = Object.values(ERoutePath);
  const routePath = path.split('?')[0];
  if (!includes(allRouterPath, routePath)) {
    return { path: '/', shouldSkip: true };
  }
  const queryStringStart = path.indexOf('?');
  return {
    path: queryStringStart < 0 ? path : path.slice(0, queryStringStart),
    queryString: queryStringStart < 0 ? undefined : path.slice(queryStringStart + 1),
    shouldSkip: false,
  };
};

export const removeQueryString = (origin: string): string => {
  let url: URL;
  try {
    url = new URL(origin);
  } catch {
    return '';
  }
  return url.origin + url.pathname;
};

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank');
};

export const useSyncCallback = (callback) => {
  const [proxyState, setProxyState] = useState({ current: false });
  const [params, setParams] = useState([]);

  const Func = useCallback(
    (...args) => {
      setParams(args);
      setProxyState({ current: true });
    },
    [proxyState],
  );

  useEffect(() => {
    if (proxyState.current === true) setProxyState({ current: false });
  }, [proxyState]);

  useEffect(() => {
    proxyState.current && callback(...params);
  });

  return Func;
};

export const identityTrack = (contactInfo: Record<string, string>) => {
  const user = auth.getUser();
  const myobVisitorID = Cookies.get('myob_visitor_id');
  user &&
    telemetry.identify(user.userId, {
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName,
      emailAddress: contactInfo.emailAddress,
      visitorId: myobVisitorID,
      userId: user.userId,
    });
};

export const getMyobVisitorId = (): string => {
  return Cookies.get('myob_visitor_id') || '';
};

export const isBelongToInternalRoutePath = (path: string): boolean => {
  const allRoutePaths = Object.values(InternalRoutePath);

  for (let index = 0; index < allRoutePaths.length; index++) {
    if (getMatchPath(path, allRoutePaths[index])) {
      return true;
    }
  }

  return false;
};

export const computePageNameFromPath = (path: string): string => {
  const allInternalRoutePaths = Object.values(InternalRoutePath);
  const defaultPageName = 'Unnamed';

  for (let index = 0; index < allInternalRoutePaths.length; index++) {
    const internalRoutePath = allInternalRoutePaths[index];
    if (getMatchPath(path, internalRoutePath)) {
      return InternalRoutePageNameMap.get(internalRoutePath) || defaultPageName;
    }
  }

  return defaultPageName;
};

function getMatchPath(path: string, routePath: InternalRoutePath) {
  const pathName = path.split('?')[0];

  return matchPath(pathName, { path: routePath, strict: true, exact: true });
}

export const isValidIdToken = (idToken: string): boolean => {
  if (!idToken) return false;

  const decoded = jwtDecode(idToken);
  const currentTime = Date.now() / 1000;

  return decoded && 'acr' in decoded && currentTime < decoded.exp;
};
