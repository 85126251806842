import { telemetry } from '@my-account/tools';
import { ETelemetryActionsAndLabels, ETelemetryEventNames } from 'telemetry/type';

export const trackClickInErrorPage = (comeFrom: string): void => {
  telemetry.track(ETelemetryEventNames.ErrorPage, {
    action: ETelemetryActionsAndLabels.Click,
    label: ETelemetryActionsAndLabels.Click,
    clickData: {
      buttonName: 'support',
      comeFrom,
    },
  });
};
