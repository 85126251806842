import { helper } from '@my-account/tools';
import { Region } from '../../../AccountDetails/type';
import { auConfig, nzConfig } from 'config';
import {
  Alert,
  Button,
  ButtonLink,
  ButtonRow,
  Checkbox,
  CheckboxGroup,
  Heading,
  PageHead,
  Separator,
  StandardTemplate,
  Text,
} from '@myob/myob-widgets';
import { openInNewTab } from '../../../../helpers/tools';
import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import { CommunicationMethod, CommunicationPreferencesDetailsInfo, InterestedTopic } from '../../type';
import { Notification } from '../../../../components/Notification';
import { ENotificationType } from '../../../../stores/reducers/notification/type';
import { AppThunkDispatch } from '../../../../stores';
import { useDispatch } from 'react-redux';

type CommunicationPreferencesFormProps = {
  communicationPreferencesDetails: CommunicationPreferencesDetailsInfo;
  onSave?: (formData: CommunicationPreferencesDetailsInfo) => void;
  onCancel: () => void;
  ref?: ForwardedRef<unknown>;
  onDismiss?: () => void;
};

const CommunicationPreferencesForm: React.FC<CommunicationPreferencesFormProps> = forwardRef(
  function _CommunicationPreferencesForm({ communicationPreferencesDetails, onSave, onCancel, onDismiss }, ref) {
    const [isEdit, setIsEdit] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const dispatch: AppThunkDispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      setIsEdit,
    }));

    const privacyDisclosureLink =
      helper.getRegionFromTimezone() === Region.NZ
        ? nzConfig.PRIVACY_DISCLOSURE_LINK
        : auConfig.PRIVACY_DISCLOSURE_LINK;

    const pageHead = <PageHead title="Communication preferences" className="page-head" />;

    const communicationPreferencesDetailsAlert = (
      <>
        <Notification onDismiss={onDismiss} />
        <Alert tone="brand" className="productAlert">
          Allow 24 hours for changes to take effect. We’ll email you with important updates, regardless of your
          preferences.
          <br />
          View the{' '}
          <ButtonLink
            textWrap="wrap"
            textAlign="left"
            lineHeight="tight"
            tone="brand"
            onClick={() => openInNewTab(privacyDisclosureLink)}
          >
            MYOB Privacy Disclosure Statement.
          </ButtonLink>
        </Alert>
      </>
    );

    const INTERESTED_TOPICS_KEY = 'interestedTopics';
    const RECEIVE_COMMUNICATIONS_BY_KEY = 'receiveCommunicationsBy';

    const [checkedItems, setCheckedItems] = useState<CommunicationPreferencesDetailsInfo>({
      interestedTopics: communicationPreferencesDetails.interestedTopics,
      receiveCommunicationsBy: communicationPreferencesDetails.receiveCommunicationsBy,
    });

    const handleCheckboxChange = (group: string, key: string) => {
      setCheckedItems((prevState) => {
        const updatedGroup = prevState[group].includes(key)
          ? prevState[group].filter((item) => item !== key)
          : [...prevState[group], key];

        return {
          ...prevState,
          [group]: updatedGroup,
        };
      });
      setIsSaveDisabled(false);
    };

    const interestedTopicsMap = [
      {
        name: 'tips-and-tricks',
        label: 'Tips, business advice and tax information',
        key: InterestedTopic.TIPS_AND_TRICKS,
      },
      { name: 'traning-and-event', label: 'Training and events', key: InterestedTopic.TRAINING_AND_EVENT },
      {
        name: 'special-offers-and-promotions',
        label: 'Offers and promotions',
        key: InterestedTopic.SPECIAL_OFFERS_AND_PROMOTIONS,
      },
      { name: 'myob-survey', label: 'MYOB survey invitations', key: InterestedTopic.SURVEYS },
    ];

    const communicationMethodsMap = [
      { name: 'email', label: 'Email', key: CommunicationMethod.EMAIL },
      { name: 'sms', label: 'SMS', key: CommunicationMethod.SMS },
      { name: 'mail', label: 'Mail', key: CommunicationMethod.MAIL },
      { name: 'phone', label: 'Phone', key: CommunicationMethod.PHONE },
    ];

    const renderCheckboxes = (mapItems, group, props) => {
      return mapItems.map((item) => (
        <Checkbox
          className="checkbox"
          key={item.name}
          name={item.name}
          label={item.label}
          checked={checkedItems[group].includes(item.key)}
          {...props}
          onChange={() => handleCheckboxChange(group, item.key)}
        />
      ));
    };

    const showCancelAndSave = () => {
      setIsEdit(true);
    };

    const handleOnSave = () => {
      if (checkedItems.receiveCommunicationsBy.length === 0) {
        dispatch({
          type: ENotificationType.Danger,
          primaryMessage:
            "Choose at least one contact method<p style='margin-bottom: 16px'> </p>If you do not want to get any marketing or promotional materials, uncheck all the boxes under 'Topics I want to know about'. We'll only contact you about your account and important updates.",
          dismissAfter: 24 * 3600 * 1000,
        });
      } else {
        dispatch({ type: ENotificationType.Clear });
        onSave(checkedItems);
      }
    };

    return (
      <>
        <StandardTemplate
          className="productTemp"
          sticky="all"
          pageHead={pageHead}
          subHeadChildren={communicationPreferencesDetailsAlert}
        >
          <CheckboxGroup
            disabled={!isEdit}
            renderCheckbox={(props) => (
              <>
                <Heading level="2">Topics I want to know about</Heading>
                <Separator />
                {renderCheckboxes(interestedTopicsMap, INTERESTED_TOPICS_KEY, props)}
                <br />
                <Heading level="2">How I want to be contacted</Heading>
                <Separator />
                {renderCheckboxes(communicationMethodsMap, RECEIVE_COMMUNICATIONS_BY_KEY, props)}
              </>
            )}
          />
        </StandardTemplate>

        <ButtonRow className="btn-row">
          {!isEdit && (
            <div data-gtm-id="edit-btn-account-page">
              <ButtonRow>
                <Button onClick={showCancelAndSave} label="Edit">
                  Edit
                </Button>
              </ButtonRow>
            </div>
          )}
          {isEdit && (
            <div data-gtm-id="cancel-btn-account-page">
              <ButtonRow>
                <Button type="secondary" label="Cancel" onClick={onCancel}>
                  Cancel
                </Button>
              </ButtonRow>
            </div>
          )}
          {isEdit && (
            <div data-gtm-id="save-btn-account-page">
              <ButtonRow>
                <Button label="Save" disabled={isSaveDisabled} onClick={handleOnSave}>
                  Save
                </Button>
              </ButtonRow>
            </div>
          )}
        </ButtonRow>
      </>
    );
  },
);

export default CommunicationPreferencesForm;
