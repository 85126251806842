import { CommunicationPreferencesDetailsInfo } from '../type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseError } from 'helpers/request';
import { fetchCommunicationPreferencesDetails } from '../../../helpers/api';

export type GetCommunicationPreferencesDetailsState = {
  isLoading: boolean;
  data: CommunicationPreferencesDetailsInfo;
  isError: boolean;
  error: ResponseError;
};

export const initialState: GetCommunicationPreferencesDetailsState = {
  isLoading: false,
  data: null,
  isError: false,
  error: {
    status: null,
    message: null,
  },
};

export const fetchCommunicationPreferencesDetailsAsync = createAsyncThunk<
  CommunicationPreferencesDetailsInfo,
  void,
  { rejectValue: ResponseError }
>('account/fetchCommunicationPreferencesDetails', async (_, { rejectWithValue }) => {
  try {
    return await fetchCommunicationPreferencesDetails();
  } catch (e) {
    return rejectWithValue(e);
  }
});

const slice = createSlice({
  name: 'get-communication-preferences-details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCommunicationPreferencesDetailsAsync.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.data = null;
      state.error = { status: null, message: null };
    });
    builder.addCase(fetchCommunicationPreferencesDetailsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
      state.error = { status: null, message: null };
    });
    builder.addCase(fetchCommunicationPreferencesDetailsAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.data = null;
      state.error = action.payload;
    });
  },
});

export const getCommunicationPreferencesReducer = slice.reducer;
