import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UpdateContactDetailsSchema, updateContactDetails } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type UpdateContactDetailsState = {
  isLoading: boolean;
  data: null;
  isError: boolean | null;
  error: ResponseError;
};

export const initialState: UpdateContactDetailsState = {
  isLoading: false,
  data: null,
  isError: null,
  error: {
    status: null,
    message: null,
  },
};

export const updateContactDetailsAsync = createAsyncThunk<
  Response,
  UpdateContactDetailsSchema,
  { rejectValue: ResponseError }
>('contact/updateContactDetails', async (data, { rejectWithValue }) => {
  try {
    await updateContactDetails(data);
    const userProfile = { firstName: data.firstname, lastName: data.lastname };
    localStorage.setItem('USER_PROFILE', JSON.stringify(userProfile));
    window.dispatchEvent(new Event('localstorage_user_profile_change'));
    return;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const slice = createSlice({
  name: 'update-contact-details',
  initialState,
  reducers: {
    resetIsUpdateError(state) {
      state.isError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateContactDetailsAsync.pending, (state) => {
      state.isLoading = true;
      state.error = { status: null, message: null };
    });
    builder.addCase(updateContactDetailsAsync.fulfilled, (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = { status: null, message: null };
    });
    builder.addCase(updateContactDetailsAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    });
  },
});

export const updateContactReducer = slice.reducer;
export const { resetIsUpdateError } = slice.actions;
